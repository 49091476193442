.nav-main {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  .navbar {
    padding: 0;

    .navbar-brand {
      .logo {
        min-height: 40px;
      }
    }

    .navbar-nav {
      .nav-link {
        padding: 1.5rem 2.5rem;
      }

      .active {
        > .nav-link {
          border-bottom: 3px solid $primary;
        }
      }
    }
  }
}

body {
  margin-top: 75px;
}

@include media-breakpoint-down(md) {
  body {
    margin-top: 54px;
  }
}
