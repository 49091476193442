$primary: #2380fb;

$navbar-light-color:                #5b646d;
$navbar-light-hover-color:          $primary;
$navbar-light-active-color:         $primary;

$badge-padding-y: 0.4rem;
$badge-padding-x: 1rem;

$badge-pill-padding-y: 0.4rem;
$badge-pill-padding-x: 1rem;

$btn-padding-y: 0.7rem;
$btn-padding-x: 1rem;

$spacers: (
  6: 6rem,
  7: 7rem,
  8: 8rem,
  9: 9rem,
  10: 10rem,
  12: 12rem,
  14: 14rem,
  16: 16rem,
  18: 18rem
);

$progress-height: 0.5rem;
